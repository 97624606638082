'use client'

import { useEffect } from 'react'

import { InternalError } from '@/components/internal-error'
import { consoleError } from '@/common/utils/console'

export default function Error({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    consoleError(error)
  }, [error])

  return <InternalError />
}
